@import '../../../../styles/root.css';
@import '../../../../styles/vars.css';

header.fixedHeader {
  position: fixed !important;
  height: 50px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000 !important;
  background-color: #fff !important;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 0;
  box-shadow: none !important;

  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    min-height: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .logo {
    width: 180px;
    margin-right: 15px;

    & img {
      max-width: 100%;
      height: auto;
    }
  }

  .gymglishSeparator {
    width: 1px;
    height: 30px;
    background-color: #e7e7e7;
    margin-right: 15px;
  }

  .projectName {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    text-align: left;
    color: #b1b1b1;
  }

  .grow {
    flex-grow: 1;
  }

  .userMenu {
    color: var(--greyDark) !important;
  }
  .userMenu:hover, .userMenu:active, .userMenu:focus {
    color: var(--greyDarker) !important;
  }

  .helpLink {
    cursor: pointer;
    margin-right: 30px;
    color: var(--greyDark);
  }
  .helpLink:hover, .helpLink:active, .helpLink:focus {
    text-decoration: none;
    color: var(--greyDarker);
  }
}

.logoutLink {
  color: var(--greyDark);
}
.logoutLink:hover, .logoutLink:active, .logoutLink:focus {
  text-decoration: none;
  color: var(--greyDarker);
}

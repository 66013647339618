:root {
  --default-grey-color: #9E9E9E;
  --black: #414141;
  --greyDarker: #3f3f3f;
  --greyDark: #757575;
  --greyBright: #9b9b9b;
  --almostWhite: #f5f5f5;
  --primary: #009688;
  --danger: #ef5350;
  --success: #4caf50;
  --warning: #fbc02d;
  --warningLight: #fff6e0;
  --idkBlue: #498fe4;
  --starterBlue: #225394;
  --mainGreen: #289b39;
  --dessertBlue: #035075;
  --basicLink: #0074c2;
  --relatedThemesGrey: #7c7a7a;
  --device-simulation-color: #99a7a5;
  --testsRed: #cc3736;
  --darkerRed: #c5201e;
  --cream: #ffffbb;
  --incompleteYellow: #F5C237;
  --darkYellow: #d89c09;
  --lockBackground: #7575757a;
  --darkGreen: #3d8c3f;
  --darkGreenPrimary: #05756c;
  --bloodOrange: #f65026;
  --transparent: rgba(0, 0, 0, 0);
  --halftransparent: rgba(0, 0, 0, 0.5);
  --title: 16px;
  --subtitle: 14px;
  --caption: 13px;
  --headerHeight: 50px;
  --contentContainerTopGap: 3.4em;
  --navigationHeight: 50px;
  --lessonEditorHeaderHeight: 60px;
}

@import '../../../../styles/vars.css';

.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
  background: var(--almostWhite);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & h3 {
    margin-top: 40px;
  }
}

.loadingContainerTransparent {
  background: rgba(255, 255, 255, 0.5);
}

.loadingContainerLocal {
  position: absolute;
}

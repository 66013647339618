/*
  These are our basic HTML elements styling definitions. Some of them were copied from Bootstrap 3.3.7's
  source code after all of Bootstrap dependencies had been removed from the project.
*/

html {
  /*background-color: green !important;*/
  font-family: sans-serif;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html * {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 500;
}

body {
  margin: 0;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  font-size: 14px;
}

.body {
  min-height: 2000px;
  padding-top: 70px;
}

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
*:before, *:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

article, aside, details,
figcaption, figure, footer,
header, hgroup, main, menu,
nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden], template {
  display: none;
}

a, a:-webkit-any-link {
  text-decoration: none;
  color: var(--basicLink);
}
a:active, a:hover {
  outline: 0;
}
a:hover, a:active, a:focus {
  text-decoration: none;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

abbr[title] {
  border-bottom: 1px dotted;
}
b, strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -.5em;
}
sub {
  bottom: -.25em;
}

img {
  border: 0;
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
hr {
  height: 0;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

pre {
  overflow: auto;
}
code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  overflow: visible;
}
button, select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  padding: .35em .625em .75em;
  margin: 0 2px;
  border: 1px solid #c0c0c0;
}
legend {
  padding: 0;
  border: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  background-color: transparent;
}
td, th {
  padding: 0;
}

th {
  text-align: left;
}

table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1 small, h2 small,
h3 small, h4 small,
h5 small, h6 small {
  font-weight: normal;
  line-height: 1;
  color: #777;
}

h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 small, h2 small, h3 small {
  font-size: 65%;
}

h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 small, h5 small, h6 small {
  font-size: 75%;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p {
  margin: 0 0 10px;
}

small {
  font-size: 85%;
}
mark {
  padding: .2em;
  background-color: #fcf8e3;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul, ol ul, ul ol, ol ol {
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}
dt, dd {
  line-height: 1.42857143;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}
blockquote p:last-child, blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}
blockquote footer, blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}
blockquote footer:before, blockquote small:before {
  content: '\2014 \00A0';
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  -webkit-box-shadow: none;
          box-shadow: none;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}


fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="search"] {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}


:global(.noDisplay) {
  display: none;
}

/* Old Bootstrap reference */
:global(.form-group) {
  margin-bottom: 15px;
}

textarea {
  resize: none;
}

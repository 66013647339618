@import './vars.css';

.initialLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
  background: var(--almostWhite);
  padding-top: 100px;
}

.initialLoading div {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: auto;
  margin-top: 4em;
}

.errorRoot {
  padding: 20px;
  height: 100vh;
  background: var(--almostWhite);
}

.dragOver {
  position: relative;
  transition: all 0.266s ease-in-out;
  border-top: 50px solid transparent;
}

.dragOver::before {
  content: '';
  position: absolute;
  top: -50px;
  width: 100%;
  height: 30px;
  border: 2px dashed var(--greyDark);
}

.dragOverLast {
  position: relative;
  transition: all 0.266s ease-in-out;
  border-bottom: 50px solid transparent;
}

.dragOverLast::before {
  content: '';
  position: absolute;
  bottom: -35px;
  width: 100%;
  height: 30px;
  border: 2px dashed var(--greyDark);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}